import AboutMe from './components/AboutMe/AboutMe';
import Career from './components/Career/Career';
import Contact from './components/Contact/Contact';
import BillSplit from './components/BillSplit/BillSplit';
import Thesis from './components/Thesis/Thesis';
import SpotifyWeather from './components/SpotifyWeather/SpotifyWeather';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import { useEffect } from 'react';

function App() {
    useEffect(() => {
        const handleScroll = () => {
            const transitionDiv = document.querySelector('.transition-effect');
            const careerDiv = document.querySelector('.career-container');
    
            // Add null checks
            if (!transitionDiv || !careerDiv) {
                return; // Exit if elements are not found
            }
    
            const careerOffsetTop = careerDiv.offsetTop;
            const scrollPosition = window.pageYOffset + window.innerHeight;
    
            if (scrollPosition >= careerOffsetTop) {
                transitionDiv.classList.add('animate');
            } else {
                transitionDiv.classList.remove('animate');
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return (
        <Router>
            <div className="nav-container">
                <header>
                    <nav>
                        <ul className="menu">
                            <li><Link to="/">About</Link></li>
                            <li>
                                <Link to="/career">Career</Link>
                                <ul className="submenu">
                                    <li><Link to="/spotify-weather">Spotify Weather</Link></li>
                                    <li><Link to="/bill-split">Bill Split</Link></li>
                                    <li><Link to="/thesis">MSEE Thesis</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/contact">Contact Me</Link></li>
                        </ul>
                    </nav>
                </header>

                <main>
                    <Routes>
                        <Route path="/" element={<AboutMe />} />
                        <Route path="/career" element={<Career />} />
                        <Route path ="/thesis" element={<Thesis />} />
                        <Route path ="/spotify-weather" element={<SpotifyWeather />} />
                        <Route path="/bill-split" element={<BillSplit />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
