// BillSplit.js
import React, { useState } from 'react';
import './BillSplit.css';

function BillSplit() {
    const [users, setUsers] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [newItem, setNewItem] = useState({ name: '', price: '' });
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
    const [tipRate, setTipRate] = useState(15); // Default tip rate
    const [taxRate, setTaxRate] = useState(7.75); // Default tax rate in percentage

    const addUser = () => {
        const username = prompt('Enter username:');
        if (username) setUsers([...users, { name: username, items: [] }]);
    };

    const deleteUser = (index) => {
        setShowDeleteConfirm(index);
    };

    const confirmDeleteUser = (index) => {
        const updatedUsers = users.filter((_, i) => i !== index);
        setUsers(updatedUsers);
        setShowDeleteConfirm(null);
    };

    const clearUserTotal = (userIndex) => {
        const updatedUsers = [...users];
        const clearedUser = updatedUsers[userIndex];
        clearedUser.items = [];

        const updatedItems = items.map(item => {
            item.assignedUsers = item.assignedUsers.filter(u => u !== userIndex);
            return item;
        });

        // Recalculate user totals based on updated assignments
        updatedUsers.forEach((user, index) => {
            const assignedItems = updatedItems.filter(item => item.assignedUsers.includes(index));
            user.items = assignedItems;
        });

        setItems(updatedItems);
        setUsers(updatedUsers);
    };

    const addItem = () => {
        if (newItem.name && newItem.price) {
            setItems([...items, { name: newItem.name, price: parseFloat(newItem.price), assignedUsers: [] }]);
            setNewItem({ name: '', price: '' });
        }
    };

    const deleteItem = (itemIndex) => {
        const updatedItems = items.filter((_, index) => index !== itemIndex);
        setItems(updatedItems);
    };

    const toggleUserSelection = (userIndex) => {
        if (selectedUsers.includes(userIndex)) {
            setSelectedUsers(selectedUsers.filter((index) => index !== userIndex));
        } else {
            setSelectedUsers([...selectedUsers, userIndex]);
        }
    };

    const assignItemToUsers = (itemIndex) => {
        const updatedItems = [...items];
        const selectedItem = updatedItems[itemIndex];

        selectedUsers.forEach(userIndex => {
            if (selectedItem.assignedUsers.includes(userIndex)) {
                selectedItem.assignedUsers = selectedItem.assignedUsers.filter(u => u !== userIndex);
            } else {
                selectedItem.assignedUsers.push(userIndex);
            }
        });

        const updatedUsers = [...users];

        updatedUsers.forEach((user, userIndex) => {
            const assignedItems = updatedItems.filter(item => item.assignedUsers.includes(userIndex));
            user.items = assignedItems;
        });

        setItems(updatedItems);
        setUsers(updatedUsers);
    };

    const calculateUserTotal = (userIndex) => {
        const user = users[userIndex];
        return user.items.reduce((acc, item) => acc + item.price / item.assignedUsers.length, 0).toFixed(2);
    };

    const calculateFinalTotals = () => {
        const taxRateDecimal = taxRate / 100;
        const tipRateDecimal = tipRate / 100;
        const totalBill = items.reduce((acc, item) => acc + item.price, 0);
        const tax = totalBill * taxRateDecimal;
        const tip = totalBill * tipRateDecimal;

        return users.map(user => {
            const userItems = user.items;
            const userTotal = userItems.reduce((acc, item) => acc + item.price / item.assignedUsers.length, 0);
            const userPercentage = userTotal / totalBill;
            const userTax = userPercentage * tax;
            const userTip = userPercentage * tip;

            return {
                name: user.name,
                itemsCount: userItems.length,
                subtotal: userTotal.toFixed(2),
                tax: userTax.toFixed(2),
                tip: userTip.toFixed(2),
                finalTotal: (userTotal + userTax + userTip).toFixed(2),
            };
        });
    };

    const showFinalResults = () => {
        const results = calculateFinalTotals();
    
        const popup = window.open('', 'Results', 'width=400,height=600');
        popup.document.write(`
            <html>
                <head>
                    <title>Split Results</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            background-color: #f4f4f9;
                            margin: 0;
                            padding: 20px;
                        }
                        h1 {
                            color: #007bff;
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        .result {
                            background: #fff;
                            border: 1px solid #ddd;
                            border-radius: 8px;
                            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                            padding: 15px;
                            margin-bottom: 10px;
                            cursor: pointer;
                        }
                        .result span {
                            font-size: 16px;
                            font-weight: bold;
                            color: #333;
                        }
                        .details {
                            font-size: 14px;
                            color: #555;
                            margin-top: 5px;
                            display: none;
                        }
                        .result.active .details {
                            display: block;
                        }
                    </style>
                </head>
                <body>
                    <h1>Split Results</h1>
                    ${results.map((result, index) => `
                        <div class="result" id="result-${index}">
                            <span>${result.name}</span>
                            <span>Total: $${result.finalTotal}</span>
                            <div class="details">
                                <p>Items Split: ${result.itemsCount}</p>
                                <p>Subtotal: $${result.subtotal}</p>
                                <p>Tax: $${result.tax}</p>
                                <p>Tip: $${result.tip}</p>
                            </div>
                        </div>
                    `).join('')}
                    <script>
                        document.querySelectorAll('.result').forEach((element) => {
                            element.addEventListener('click', function () {
                                this.classList.toggle('active');
                            });
                        });
                    </script>
                </body>
            </html>
        `);
    };
    
    return (
        <div className="bill-split-container">
            <h1>Bill Split App</h1>
    
            <div className="bill-split-settings-section">
                <h2>Settings</h2>
                <label>
                    Tip Percentage:
                    <input
                        type="number"
                        value={tipRate}
                        onChange={(e) => setTipRate(e.target.value)}
                    />
                </label>
                <label>
                    Sales Tax Percentage:
                    <input
                        type="number"
                        value={taxRate}
                        onChange={(e) => setTaxRate(e.target.value)}
                    />
                </label>
            </div>
    
            <div className="bill-split-section">
                <h2>Add Items</h2>
                <input
                    type="text"
                    placeholder="Item Name"
                    value={newItem.name}
                    onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                />
                <input
                    type="number"
                    placeholder="Price"
                    value={newItem.price}
                    onChange={(e) => setNewItem({ ...newItem, price: e.target.value })}
                />
                <button onClick={addItem}>Add Item</button>
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>
                            <span onClick={() => assignItemToUsers(index)}>
                                {item.name}: ${item.price.toFixed(2)}
                            </span>
                            <span className="assigned-users">
                                {item.assignedUsers.map(userIndex => users[userIndex]?.name || '').join(', ')}
                            </span>
                            <button onClick={() => deleteItem(index)}>Delete</button>
                        </li>
                    ))}
                </ul>
            </div>
    
            <div className="bill-split-user-section">
                <h2>Users</h2>
                <button onClick={addUser}>Add User</button>
                <ul>
                    {users.map((user, index) => (
                        <li key={index}>
                            <span
                                onClick={() => toggleUserSelection(index)}
                                className={selectedUsers.includes(index) ? 'selected' : ''}>
                                {user.name} - Total: ${calculateUserTotal(index)}
                            </span>
                            <button onClick={() => deleteUser(index)}>Delete</button>
                            <button onClick={() => clearUserTotal(index)}>Clear Total</button>
                            {showDeleteConfirm === index && (
                                <div className="confirm">
                                    <p>Are you sure?</p>
                                    <button onClick={() => confirmDeleteUser(index)}>Yes</button>
                                    <button onClick={() => setShowDeleteConfirm(null)}>No</button>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
    
            <button className="finish-button" onClick={showFinalResults}>Finish</button>
        </div>
    );
    
}

export default BillSplit;
