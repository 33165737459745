import React, { useState, useEffect } from 'react';
// Import Swiper React components and the core Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import SwiperCore from 'swiper';
import AnimatedTitle from '../AnimatedTitle/AnimatedTitle';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import '../../App.css';
import './AboutMe.css';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]);

export default function AboutMe() {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Dynamically fetch slide data
  useEffect(() => {
    async function fetchSlides() {
      try {
        // Simulate an API call or fetch local data
        const response = await fetch('/data/slides.json'); // Adjust the path to your data file
        const data = await response.json();
        setSlides(data);
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    }
    fetchSlides();
  }, []);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  const getNextSlide = () => slides[(currentIndex + 1) % slides.length];
  const getPrevSlide = () => slides[(currentIndex - 1 + slides.length) % slides.length];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-4">About Me</h2>
        <div className="flex justify-center mb-8">
            <AnimatedTitle />
        </div>
        
        <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-12 items-center max-w-[1400px] mx-auto px-4">
          {/* Carousel Section */}
          <div className="w-full max-w-2xl mx-auto">
            {slides.length > 0 && (
              <div className="relative rounded-2xl shadow-2xl hover:shadow-3xl transition-shadow duration-300">
                <Swiper
                  className="carousel-container"
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                  spaceBetween={30}
                  slidesPerView={1}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  pagination={{ clickable: true }}
                  autoplay={false}
                  loop={true}
                  effect="fade"
                  onSlideChange={handleSlideChange}
                >
                  {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <div className="relative group h-full flex items-center justify-center">
                        <img 
                          src={slide.url} 
                          alt={`Slide ${index}`}
                          className="w-full h-full object-contain"
                        />
                        <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/50 to-transparent opacity- group-hover:opacity-100 transition-opacity duration-300">
                          <p className="text-white text-lg px-4 py-2 rounded-lg bg-black/50 max-w-[90%] mx-auto">
                            {slide.caption}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div
                  className="swiper-button-next w-12 h-12 rounded-full bg-white/80 shadow-lg backdrop-blur-sm transition-transform duration-300 hover:scale-110"
                  style={{ backgroundImage: `url(${getNextSlide()?.url || ''})` }}
                >
                  <i className={`bi ${getNextSlide()?.icon || ''}`}></i>
                </div>
                <div
                  className="swiper-button-prev w-12 h-12 rounded-full bg-white/80 shadow-lg backdrop-blur-sm transition-transform duration-300 hover:scale-110"
                  style={{ backgroundImage: `url(${getPrevSlide()?.url || ''})` }}
                >
                  <i className={`bi ${getPrevSlide()?.icon || ''}`}></i>
                </div>
              </div>
            )}
          </div>

          {/* Text Section */}
          <div className="backdrop-blur-sm bg-white/90 p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 flex items-center md:max-w-2xl md:mx-auto w-full">
            <div className="prose prose-lg max-w-none">
              <p className="text-gray-800 leading-relaxed mb-6 text-justify">
                Thanks for visiting my website! I'm an electrical engineer on paper, but at heart, 
                I'm someone who thrives on <span className="text-blue-600 font-semibold">solving complex problems</span> and 
                <span className="text-blue-600 font-semibold"> learning new things</span>. 
                Whether it's mastering software tools or tackling a challenging project, 
                I'm always looking for ways to grow and enjoy the process.
              </p>
              <p className="text-gray-800 leading-relaxed text-justify">
                Feel free to explore my website, and I'd love to hear any suggestions for improvements or ideas on what you'd like to see here.
                If you'd like to chat or network, don't hesitate to reach out!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
