import React, { useState} from 'react';
import './SpotifyWeather.css';

const CLIENT_ID = '5a7af839e521438095c92b41a5a5fe40';
const CLIENT_SECRET = 'fcb12da1c7134303aac6ed58222d8d77';
const WEATHER_API_KEY = '50dc0137f0306bf11fb27c9c2fe92689';
const WEATHER_API_URL = `https://api.openweathermap.org/data/2.5/weather?zip=94107,us&units=imperial&appid=${WEATHER_API_KEY}`;

const moodToPlaylistId = {
    chill: '3orcllxQnhCM4lGjrg0Blq',
    happy: '7GhawGpb43Ctkq3PRP1fOL',
    sad: '2CMcLwYip2nL0urcHWawOB',
    calm: '3l6b0zuXjgyPxLK6PIAqED',
    mixed: '774kUuKDzLa8ieaSmi8IfS', // Fallback or default playlist
};

function SpotifyWeather() {
    const [accessToken, setAccessToken] = useState('');
    const [playlist, setPlaylist] = useState(null);
    const [weather, setWeather] = useState('');
    const [mood, setMood] = useState('');
    const [status, setStatus] = useState('');
    const [error, setError] = useState(null);

    async function fetchSpotifyAccessToken() {
        try {
            setStatus('Fetching Spotify Access Token...');
            const response = await fetch('https://accounts.spotify.com/api/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
                },
                body: 'grant_type=client_credentials',
            });
    
            if (!response.ok) {
                throw new Error(`Failed to fetch access token: ${response.statusText}`);
            }
    
            const data = await response.json();
            console.log('Spotify Token Response:', data);
    
            if (!data.access_token) {
                throw new Error('Access token missing in response');
            }
    
            setAccessToken(data.access_token);
            setStatus('Spotify Access Token fetched successfully');
        } catch (err) {
            console.error('Error fetching Spotify access token:', err);
            setError('Failed to fetch Spotify access token');
            setStatus('Error fetching Spotify Access Token');
        }
    }
    

    async function fetchWeather() {
        try {
            setStatus('Fetching Weather Data...');
            const response = await fetch(WEATHER_API_URL);
            if (!response.ok) {
                throw new Error(`Weather API error: ${response.statusText}`);
            }
            const data = await response.json();
            if (data.weather && data.weather[0] && data.weather[0].main) {
                const currentWeather = data.weather[0].main.toLowerCase();
                setWeather(currentWeather);
                setStatus('Weather Data fetched successfully');
                console.log('Weather:', currentWeather);
                
                return currentWeather;
            } else {
                throw new Error('Invalid weather data structure');
            }
        } catch (err) {
            setError('Failed to fetch weather data');
            setStatus('Error fetching Weather Data');
            return null;
        }
    }

    async function fetchPlaylistForMood(currentWeather) {
        try {
            setStatus('Fetching Playlist...');
            let mood = determineMood(currentWeather);
            console.log('Mood determined:', mood);
            setMood(mood);

            const playlistId = moodToPlaylistId[mood] || moodToPlaylistId['mixed']; // Fallback to 'mixed' if mood not found
            const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Spotify Playlist API error: ${response.statusText}`);
            }

            const playlistData = await response.json();
            console.log('Spotify Playlist Data:', playlistData);
            setPlaylist(playlistData);
            setStatus('Playlist fetched successfully');
        } catch (err) {
            console.error('Error fetching playlist:', err);
            setError('Failed to fetch playlist');
            setStatus('Error fetching Playlist');
        }
    }

    function determineMood(weather) {
        if (!weather) return 'mixed';
        if (weather.includes('rain')) return 'sad';
        if (weather.includes('cloud')) return 'chill';
        if (weather.includes('clear')) return 'happy';
        if (weather.includes('snow')) return 'calm';
        return 'mixed'; // Default mood
    }

    async function handleFetchData() {
        try {
            await fetchSpotifyAccessToken();
            const currentWeather = await fetchWeather();
            if (currentWeather && accessToken) {
                await fetchPlaylistForMood(currentWeather);
            }
        } catch (err) {
            console.error('Error during data fetching:', err);
        }
    }

    return (
        <div className={`spotify-weather-app spotify-weather-${mood} fade-in`}>
            <h1>Weather-Based Playlist</h1>
            {status && <p className="spotify-weather-status">{status}</p>}
            {error && <p className="spotify-weather-error">{error}</p>}
            <button className="spotify-weather-button" onClick={handleFetchData}>Get Weather and Playlist</button>
            {playlist ? (
                <div className="spotify-weather-playlist">
                    <h3>Weather: {weather} | Mood: {mood}</h3>
                    <a href={playlist.external_urls?.spotify || '#'} target="_blank" rel="noopener noreferrer">
                        <img src={playlist.images[0]?.url || 'fallback-image-url.jpg'} alt={playlist.name || 'Playlist'} />
                    </a>

                    <p>{playlist.name}</p>
                </div>
            ) : (
                <p>Click the button to fetch data</p>
            )}
        </div>
    );
}

export default SpotifyWeather;
