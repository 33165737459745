import React from 'react';
import './Thesis.css';

const Thesis = () => {
  return (
    <div className="thesis-container">
      <header className="mb-4">
        <h1 className="thesis-header">MSEE Thesis</h1>
        <h2 className="thesis-subheader">Enhanced Swim Pose Recognition through Dataset Expansion and Optimized Training Techniques</h2>
      </header>
      <section>
        <p className="thesis-paragraph">
          Click the link below to access the full thesis document:
        </p>
        <a 
          href="/data/thesis-report.pdf" 
          target="_blank" 
          rel="noopener noreferrer"
          className="btn btn-primary"
        >
          Open Thesis PDF
        </a>
      </section>
    </div>
  );
};

export default Thesis;
