import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import EmailJS
import ReCAPTCHA from "react-google-recaptcha"; // Import Google reCAPTCHA
import "bootstrap/dist/css/bootstrap.min.css";
import { Envelope, Telephone, Linkedin, Github } from "react-bootstrap-icons";
import "./Contact.css";
import Swal from "sweetalert2"; // Import SweetAlert2

export default function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });
  const [captchaValue, setCaptchaValue] = useState(null); // reCAPTCHA value

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleCaptcha = (value) => {
    setCaptchaValue(value); // Set the reCAPTCHA value
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      Swal.fire({
        title: "reCAPTCHA Required",
        text: "Please complete the reCAPTCHA verification.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const templateParams = {
      from_name: formData.fullName,
      reply_to: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        "service_q0syfit",
        "template_i2cech8",
        templateParams,
        "GVkQjOkZjN69w_0nG"
      )
      .then(() => {
        Swal.fire({
          title: "Success!",
          text: "Message sent successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setFormData({ fullName: "", email: "", message: "" });
        setCaptchaValue(null); // Reset reCAPTCHA
      })
      .catch((error) => {
        console.error("EmailJS Error:", error);
        Swal.fire({
          title: "Error!",
          text: "Failed to send the message. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="contact-container">
        <div className="contact-row">
            <div className="contact-col-left">
                <img src="/austinadam.jpeg" alt="Contact" className="contact-image" />
            </div>
            <div className="contact-col-right">
                <h2>Contact Me</h2>
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className="form-group">
                        <label htmlFor="fullName" className="form-label">
                            Full Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            placeholder="Your Full Name"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="form-label">
                            E-mail
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message" className="form-label">
                            Message
                        </label>
                        <textarea
                            className="form-control"
                            id="message"
                            rows="3"
                            placeholder="Your Message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <ReCAPTCHA
                        sitekey="6LeEAp4qAAAAACz_dBcr8sVdOuDkkVipPb2rjUfN"
                        onChange={handleCaptcha}
                        className="contact-recaptcha"
                    />
                    <button type="submit" className="contact-submit-button">
                        Send Message
                    </button>
                </form>

                <div className="contact-details">
                    <p>
                        <strong>Email:</strong> austin.s.adam@gmail.com
                    </p>
                    <p>
                        <strong>Location:</strong> San Francisco, California
                    </p>
                </div>

                <div className="contact-social-icons">
                    <a href="https://www.linkedin.com/in/a-s-adam/" target="_blank" rel="noopener noreferrer">
                        <Linkedin size={32} />
                    </a>
                    <a href="tel:+14088598570">
                        <Telephone size={32} />
                    </a>
                    <a href="mailto:austin.s.adam@gmail.com">
                        <Envelope size={32} />
                    </a>
                    <a href="https://github.com/a-s-adam" target="_blank" rel="noopener noreferrer">
                        <Github size={32} />
                    </a>
                </div>
            </div>
        </div>
    </div>
);

}
