import React, { useState, useEffect, useMemo } from 'react';

const AnimatedTitle = () => {
  const titles = useMemo(() => [
    "Electrical Engineer",
    "Software Developer",
    "Problem Solver",
    "Tech Enthusiast",
    "Full Stack Developer"
  ], []);

  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const currentTitle = titles[currentIndex];
    
    const updateText = () => {
      if (!isDeleting) {
        if (displayText.length < currentTitle.length) {
          setDisplayText(currentTitle.slice(0, displayText.length + 1));
          return 80; // Type speed
        } else {
          setIsDeleting(true);
          return 2000; // Pause before deleting
        }
      } else {
        if (displayText.length === 0) {
          setIsDeleting(false);
          setCurrentIndex((currentIndex + 1) % titles.length);
          return 500; // Pause before typing next
        }
        setDisplayText(displayText.slice(0, -1));
        return 40; // Delete speed
      }
    };

    const timeout = setTimeout(() => {
      const nextDelay = updateText();
      clearTimeout(timeout);
      setTimeout(updateText, nextDelay);
    }, 100);

    return () => clearTimeout(timeout);
  }, [displayText, currentIndex, isDeleting, titles]);

  return (
    <div className="text-purple-900 text-xl md:text-2xl font-semibold">
      <span className="inline-block">{displayText}</span>
      <span className="inline-block w-[3px] h-[1.2em] bg-indigo-100 ml-1 animate-blink"></span>
    </div>
  );
};

export default AnimatedTitle; 